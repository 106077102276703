<div class="header-buttons font-poppins">
  <div class="btn-group header-buttons-wr text-base text-white" role="group" aria-label="Basic example">
    <button
      type="button"
      class="bg-blue-700 text-white filter-btn rounded-lg"
      name="isFiltered"
      [value]="filterIsOpen"
      (click)="filterIsOpen = !filterIsOpen"
    >
      @if(filterCount){
        <span class="bg-white text-blue-700 mr-2 mb-1 h-3 px-1 rounded-md font-bold">{{ filterCount }}</span>
      }
      <span>Filters</span>
      <i class="ml-2 fas" [ngClass]="filterIsOpen ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
      @if(filterCount){
        <button (click)="clearAll(); $event.stopPropagation()">
          <i class="fa fa-times text-white ml-3" aria-hidden="true"></i>
        </button>
      }

    </button>
  </div>
  <div class="flex header-buttons-wr">
    <button
      class="order_actions bg-gray-200"
      (click)="addShopOrder()"
    >
      <div class="flex items-center text-center">
        <i class="fas ml-auto fa-plus mr-1"></i>
        <span class="mr-auto">New Work Order</span>
      </div>
    </button>
    <button
      class="order_actions bg-gray-200"
      title="Recovery deleted quotes"
      (click)="this.onFilterChange.emit({recovery: filters?.recovery ? false : true })"
    >
      <div class="flex items-center text-center">
        @if(filters?.recovery){
          <i class="fa-solid ml-auto fa-angle-left mr-2"></i>
        }
        <span [ngClass]="{'ml-auto': !filters?.recovery}" class="mr-auto">{{filters?.recovery ? 'Back' : 'Recovery'}}</span>
      </div>
    </button>
  </div>
</div>

@if(filterIsOpen){
  <form class="bg-white border border-gray-300 rounded-lg p-3 font-poppins">
    <div class="shop-filter-row">
      <div class="orderNumber">
        <app-input
            [value]="filters?.orderNumber"
            [label]="'Order Number'"
            [idInfo]="'orderNumber'"
            [debounceEnable]="true"
            (onSave)="onFilterChange.emit({orderNumber: $event})"
          ></app-input>
      </div>
      <div class="refNumber">
        <app-input
            [value]="filters?.refNumber"
            [label]="'REF'"
            [idInfo]="'REF'"
            [debounceEnable]="true"
            (onSave)="onFilterChange.emit({refNumber: $event})"
          ></app-input>
      </div>
      <div class="truck-shop">
        <div class="flex flex-col">
          @if(filters?.shopId){
            <app-input
              [value]="filters?.shopName"
              [idInfo]="'truck-shop'"
              [isReadOnly]="true"
              [options]="{canRemove: true}"
              [label]="'Mechanic'"
              [debounceEnable]="true"
              (onRemove)="setShop(null, null)"
            ></app-input>
          }@else {
            <app-search
            [label]="'Truck Shop'"
            [resource]="'CONTACT'"
            [idInfo]="'search-by-shopId'"
            [method]="'search'"
            [filter]="{type: 'TRUCK SHOP', limit: 25, sort: 'quoteCount', dir: 'desc', isAccount: true}"
            (onChange)="setShop($event._id, $event.name)"
          ></app-search>
          }

          <div class="mt-2">
            <app-btn-toggle
              [value]="filters?.excludeTruckShop"
              [label]="'Exclude Truck Shop'"
              [idInfo]="'excludeTruck-toggle'"
              (onToggle)="onFilterChange.emit({excludeTruckShop: !filters.excludeTruckShop})"
            >
            </app-btn-toggle>
          </div>
        </div>
      </div>

      @if(carrierId){
        <div class="mechanicId">
          @if(filters?.mechanicId){
            <app-input
            [value]="filters?.mechanicName"
            [idInfo]="'mechanic'"
            [isReadOnly]="true"
            [options]="{canRemove: true}"
            [label]="'Mechanic'"
            [debounceEnable]="true"
            (onRemove)="setMechanic({ _id: null, name: null })"
          ></app-input>
          }@else {
            <app-search
            [label]="'Mechanic'"
            [resource]="'CONTACT'"
            [idInfo]="'search-by-mechanicId'"
            [method]="'search'"
            [filter]="{accountId: carrierId, type: 'MECHANIC', limit: 25, sort: 'quoteCount', dir: 'desc'}"
            (onChange)="setMechanic({_id: $event._id, name: $event.name})"
          ></app-search>
          }

      </div>
      }@else {
        <ng-container *ngTemplateOutlet="selectCarrier"></ng-container>
      }

      <div class="customerId">
        @if(filters?.customerId){
          <app-input
          [value]="filters?.customerName"
          [isReadOnly]="true"
          [idInfo]="'customer'"
          [options]="{canRemove: true}"
          [label]="'Customer'"
          [debounceEnable]="true"
          (onRemove)="onFilterChange.emit({ customerId: null, customerName: null, driverId: null, driverName: null, unitId: null, unitName: null, units: null})"
        ></app-input>
        }@else {
          <app-search
          [label]="'Customer'"
          [resource]="'CONTACT'"
          [idInfo]="'search-by-customerId'"
          [method]="'search'"
          [filter]="{limit: 25, sort: 'quoteCount', dir: 'desc'}"
          (onChange)="onFilterChange.emit({ customerId:$event._id, customerName: $event.name })"
        ></app-search>
        }

      </div>

      @if(carrierId){
        <div class="driverId relative">
          @if(!filters?.customerId && !filters?.units){
            <p class="absolute select-customer" style="z-index: 110;">Select Customer</p>
          }
          @if(filters?.driverId){
            <app-input
              [value]="filters?.driverName"
              [isReadOnly]="true"
              [idInfo]="'driver'"
              [options]="{canRemove: true}"
              [label]="'Driver'"
              [debounceEnable]="true"
              (onRemove)="onFilterChange.emit({ driverId: null, driverName: null })"
            ></app-input>
          }@else {
            <app-search
            [label]="'Driver'"
            [resource]="'CONTACT'"
            [idInfo]="'search-by-driverId'"
            [method]="'search'"
            [filter]="{accountId: filters?.customerId, type: 'DRIVER', limit: 25, sort: 'quoteCount', dir: 'desc'}"
            (onChange)="onFilterChange.emit({ driverId: $event._id, driverName: $event.name })"
          ></app-search>
          }

        </div>
      }@else {
        <ng-container *ngTemplateOutlet="selectCarrier"></ng-container>
      }
      <ng-template #selectCarrier>
        <span>Select Carrier</span>
      </ng-template>

      <div class="serviceType">
        <app-select
            [value]="filters?.serviceType || ''"
            [label]="'Service Type'"
            [idInfo]="'service--Type'"
            [list]="_serviceTypes"
            (onSave)="onFilterChange.emit({serviceType: $event})"
          ></app-select>
      </div>
      <div class="noInvoice">
        <div [ngStyle]="{'pointer-events': filters?.noInvoice ? 'none' : null}">
          <app-input
            [idInfo]="'inv-num'"
            [value]="filters?.invoiceNumber"
            [label]="'Invoice Number'"
            [debounceEnable]="true"
            (onSave)="onFilterChange.emit({invoiceNumber: $event})"
          ></app-input>
        </div>
        <div class="mt-2">
          <app-btn-toggle
            [value]="filters?.noInvoice"
            [label]="'Missing Invoice'"
            [idInfo]="'noInvoice-toggle'"
            (onToggle)="onFilterChange.emit({noInvoice: !filters.noInvoice, invoiceNumber: null})"
          >
          </app-btn-toggle>
        </div>
      </div>
      <div class="desc">
        <app-input
          [value]="filters?.desc"
          [label]="'Description'"
          [idInfo]="'Description'"
          [debounceEnable]="true"
          (onSave)="onFilterChange.emit({desc: $event})"
        ></app-input>
      </div>
      <div class="totalAmount">
        <app-price
          [value]="filters?.totalAmount"
          [label]="'Total Amount'"
          [debounceEnable]="true"
          (onSave)="onFilterChange.emit({totalAmount: $event})"
        ></app-price>
      </div>
      <div class="arrivalAt">
        <div>
          <app-date
          [parent]="filters"
          [title]="'Arrival Date'"
          [label]="'Arrival Date'"
          [startKey]="'arrivalStart'"
          [endKey]="'arrivalEnd'"
          [options]="{ mode: 'range', rounded_top: true}"
          (onSave)="onFilterChange.emit($event)"></app-date>
        </div>
        <div class="">
          <app-date
            [parent]="filters"
            [title]="'Departure Date'"
            [label]="'Departure Date'"
            [startKey]="'departureStart'"
            [endKey]="'departureEnd'"
            [options]="{ mode: 'range', rounded_bottom: true }"
            (onSave)="onFilterChange.emit($event)"></app-date>
        </div>
      </div>
      <div class="completedAt">
        <div>
          <app-date
            [parent]="filters"
            [title]="'Completed'"
            [label]="'Completed Start'"
            class="due-by-date"
            [startKey]="'completedAtStart'"
            [options]="{ rounded_top: true}"
            (onSave)="onFilterChange.emit($event)"
          ></app-date>
        </div>
        <div class="">
          <app-date
            [parent]="filters"
            [title]="'Completed'"
            [label]="'Completed End'"
            class="due-by-date"
            [startKey]="'completedAtEnd'"
            [options]="{ rounded_bottom: true }"
            (onSave)="onFilterChange.emit($event)">
          </app-date>
        </div>
      </div>
    </div>
      <div class="flex units-filter">
        <div style="width: 20%;" class="relative">
          @if(!filters?.customerId && !filters?.units){
            <p class="absolute select-customer" style="z-index: 110;">Select Customer</p>
          }
          <app-search
            [idInfo]="'equipment-search'"
            [label]="'Unit'"
            [resource]="'EQUIPMENT'"
            [isReadOnly]="!filters?.customerId"
            [options]="{focus: true, leftMarginMiddle: true}"
            [method]="'search'"
            [filter]="{carrierId: filters?.customerId}"
            (onChange)="setUnit($event)"
          ></app-search>
        </div>
        <div class="units ml-3" style="width: 80%;">
          @for(unit of filters?.units; track unit.unitId){
            <div
              class="unit-item"
              role="group"
              aria-label="Small button group"
            >
              <div class="flex rounded-lg font-medium items-center"
              [ngClass]="{'unitTruckName': unit?.equipmentType === 'TRUCK', 'unitTrailerName': unit?.equipmentType === 'TRAILER'}"
              >
                <i class="fa-solid" [ngClass]="{'fa-truck': unit?.equipmentType === 'TRUCK', 'fa-trailer': unit?.equipmentType === 'TRAILER'}" [ngStyle]="{'color': (unit?.equipmentType === 'TRUCK') ? '#1E429F' : (unit?.equipmentType === 'TRAILER') ? '#5521B5' : ''}"></i>
                <span class="ml-2 name">{{unit?.unitName}}</span>
                <app-btn-confirm
                  class="ml-2"
                  [color]="(unit?.equipmentType === 'TRUCK') ? '#3F83F8' : (unit?.equipmentType === 'TRAILER') ? '#9061F9' : ''"
                  (onConfirm)="removeUnit(unit.unitId)"
                ></app-btn-confirm>
              </div>
            </div>
          }
        </div>
      </div>
    <div class="btn-reset-wr">
      <button class="btn-reset" (click)="clearAll()">Reset</button>
    </div>
  </form>
}

<ng-template #selectCarrier>
  <span>Select Carrier</span>
</ng-template>

<div class="filter_shop_container">
  @if(loading){
    <div class="flex items-end filter-shop w-full">
      <div style="width:45%">
        <app-preloader [type]="'LARGE_LIST'" [customHeight]="'45px'" [count]="1"></app-preloader>
        <div class="filter_shop_small_hidden mt-1">
          <app-preloader [type]="'LARGE_LIST'" [customHeight]="'45px'" [count]="1"></app-preloader>
        </div>
      </div>
      <div class="filter_shop_hidden_limit">
        <app-preloader [type]="'LARGE_LIST'" [customHeight]="'45px'" [count]="1"></app-preloader>
      </div>
    </div>

  }@else {
    <div class="flex items-end filter-shop w-full">
      <div class="filter-shop-left">
        <div class="filter-shop-item filters">
          <app-filters
            [status]="filterTitles"
            [filters]="filters"
            [property]="'status'"
            (filterSet)="emitOnFilterChange({ status: $event.status })"
          ></app-filters>
        </div>
        @if(stats){
          <div>
            @if(!filterCount){
              <div class="pb-1 stats-item-from" style="background: #f2f3f8;">
                From {{firstDay | date : 'MM/dd/yy' }} to {{lastDay | date : 'MM/dd/yy'}}
              </div>
            }
            <div class="filter-shop-item stats" >
              <div class="stats-item">
                <span class="param">Labor:</span>
                <span class="value">${{stats?.laborTotal | myCurrency}}</span>
              </div>
              <div class="stats-item">
                <span class="param">Parts:</span>
                <span class="value">${{stats?.partsTotal | myCurrency}}</span>
              </div>
              <div class="stats-item">
                <span class="param">Total:</span>
                <span class="value">${{stats?.total | myCurrency}}</span>
              </div>
              <div class="stats-item">
                <span class="param">Hours:</span>
                <span class="value">{{stats?.qtyTotal||0}}</span>
              </div>
            </div>
          </div>
        }

      </div>
      <div class="flex items-center filter-shop-right">
        <div class="show-limit">
          <app-select
            [idInfo]="'stats'"
            [list]="limits"
            [value]="filters?.limit || 25"
            [keyName]="{key:'key', name:'name'}"
            (onSave)="setLimit($event)"
            [options]="{withoutLabel: true, noFirstOption: true}"
          ></app-select>
        </div>

        <span class="font-normal totalCount" style="color: rgba(0, 0, 0, 0.555);"><span class="font-bold"  style="color: rgba(0, 0, 0, 0.452);">{{ total }}</span> Total</span>
      </div>
    </div>
  }
</div>


