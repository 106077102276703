import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core'
import * as fromCoreStore from '@app/core/store'
import * as fromUserStore from '@app/users/store'
import * as fromShopStore from '@app/shop/store'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
const notFilterFields = ['customerId', 'page', 'limit', 'unit', 'units', 'driverDetails', 'driverLocation', 'stats', 'unitDetails','arrivalEnd','departureEnd', 'excludeTruckShop']
@Component({
  selector: 'app-shop-filter',
  templateUrl: './shop-filter.component.html',
  styleUrls: ['./shop-filter.component.scss']
})
export class ShopFilterComponent implements OnInit, OnDestroy {
  @Input()
  set _currentUser(v) {
    if(v){
      this.carrierId = v.company._id
      this.carrierName = v.company.name
      this.currentUser = v
    }
  }
  currentUser
  @Input()
  set filterSetter(v) {
    this.filters = v
    this.filterCount = 0
    Object.keys(v).forEach(k => {
      if ( k !== 'sortCriteria' && k !== 'reverseCriteria' && !k.includes('Name') && !notFilterFields.includes(k)) {
        if (v[k]) {
          this.filterCount++
        }
      }
    })
    this.cdr.detectChanges()
  }
  filters

  @Input()
  stats

  @Input()
  total

  filterTitles

  arr = []

  limit = 25
  limits = [
    {key: 25, name: 'Show 25'},
    {key: 50, name: 'Show 50'},
    {key: 75, name: 'Show 75'},
    {key: 100, name: 'Show 100'},
  ];

  @Input()
  set counts(v) {
    let names = [
      {name: 'ALL', count: '', key: 'ALL'},
      {name: 'NEW', count: '', key: 'NEW'},
      {name: 'ON-HOLD', count: '', key: 'ON_HOLD'},
      {name: 'ACTIVE', count: '', key: 'ACTIVE'},
      {name: 'COMPLETED', count: '', key: 'COMPLETED'},
      {name: 'CLOSED', count: '', key: 'CLOSED'}
    ];

    if (v) {
      for (let val of v) {
        for (let k of names) {
          if (val._id == k.key) {
            k.count = val.count
          }
        }
      }
      this.filterTitles = names
    }
  }


  @Input()
  set serviceTypes(val){
    if(val){
      this._serviceTypes = Object.keys(val).map(key=>{
          return {key:key, name:val[key]}
        })
      }
    }

  @Input()
  loading

  @Output()
  onFilterChange = new EventEmitter<any>()

  destroyed$ = new Subject<boolean>()
  filterIsOpen = true
  filterCount = 0
  carrierId: string
  carrierName: string

  _serviceTypes
  firstDay
  lastDay
  constructor(
    private coreStore: Store<fromCoreStore.State>,
    private cdr: ChangeDetectorRef
  ) {
    const now = new Date();
    this.firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    this.lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  }

  ngOnInit(): void {
  }

  emitOnFilterChange (value) {
    this.onFilterChange.emit(value)
  }


  setMechanic(event) {
    this.onFilterChange.emit({ mechanicId: event._id, mechanicName: event.name })
  }

  setUnit(equipment) {
    this.filters.units = [{ unitId: equipment?._id, unitName: equipment?.name, equipmentType: equipment?.equipmentType }, ...this?.filters?.units?.filter(u=>u.unitId!==equipment?._id)||[]]
    this.filters.unitId = `${equipment?._id}${this.filters.unitId ? ','+this.filters.unitId : ''}`
    this.onFilterChange.emit({unitId: this.filters.unitId})
  }
  removeUnit(id){
    this.filters.units = this.filters.units.filter(u=>u.unitId!==id)
    this.filters.unitId = this.filters.unitId.split(',').filter(unitId=>unitId!==id).join(',')
    this.onFilterChange.emit({unitId: this.filters.unitId})
  }

  setCarrier(carrierId, carrierName) {
    this.carrierId = carrierId
    this.carrierName = carrierName
  }

  setLimit(limit) {
    this.onFilterChange.emit({ limit })
  }

  setShop(shopId, shopName) {
    this.onFilterChange.emit({ shopId, shopName })
  }

  clearAll() {
    this.coreStore.dispatch(
      new fromShopStore.ResetFilter({})
    )
  }

  addShopOrder() {
    this.coreStore.dispatch(
      new fromShopStore.CreateShopOrder({customerId:this.carrierId})
    )
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
